import React from "react";
import { fullWidthBox } from "@utils/styled-components";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import Img from "gatsby-image";
import DetailsLarge from "@components/svg/details-large"

const Wrapper = tw(motion.div)`
  relative flex flex-wrap max-w-380 mx-auto mb-50 px-20
  sm:max-w-none sm:px-50
  md:mb-100
  lg:mb-265 lg:px-110
`;

const BoxFullWidth = tw(motion.div)`relative w-full`;

const BoxHalfWidth = tw(motion.div)`relative w-full sm:w-1/2`;

const ImageMain = tw(Img)`mb-20 sm:mt-60 lg:mt-75`;

const ImageSmall = tw(Img)`mb-20 sm:mb-0 sm:border-transparent`;

const ImageLeft = tw(ImageSmall)`sm:border-r-10 `;

const ImageRight = tw(ImageSmall)`sm:border-l-10`;

const DetailsWrapper = tw.div`
  hidden transform -scale-x-100
  md:block md:absolute md:-top-25 md:-right-60 md:w-200
  lg:-top-30 lg:-right-80 lg:w-340
`;

const Gradient = tw.div`
  absolute top-0 w-full h-300 bg-portfolioAxell
  md:h-400
  lg:h-500
  xl:h-645
`;

const GradientWrapper = tw(fullWidthBox)`hidden h-0 -z-1 sm:block sm:absolute`;

const AxellLogisticsGallery = ({ width, scroll }: { width: number, scroll: any }) => {
  const data = useStaticQuery(graphql`
    query {
      imageMain: file(relativePath: { eq: "cases/axell-main.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      imageLeft: file(relativePath: { eq: "cases/axell-left.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      imageRight: file(relativePath: { eq: "cases/axell-right.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();
  const RefSecond = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);
  const [sectionStartSecond, setSectionStartSecond] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 400], [0, 1]);
  const top = useTransform(scroll, [sectionStart + 150, sectionStart + 550], [120, 0]);
  const opacityDelay = useTransform(scroll, [sectionStartSecond + 50, sectionStartSecond + 450], [0, 1]);
  const bottom = useTransform(scroll, [sectionStartSecond + 50, sectionStartSecond + 450], [-120, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;
    const offsetStartSecond = window && RefSecond.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
    setSectionStartSecond(offsetStartSecond);
  });

  return (
    <Wrapper ref={Ref} style={ width >= 1024 && { opacity }}>
      <GradientWrapper>
        <Gradient />
      </GradientWrapper>
      <BoxFullWidth style={ width >= 1024 && { top }}>
        <ImageMain fluid={data.imageMain.childImageSharp.fluid} />
      </BoxFullWidth>
      <BoxHalfWidth ref={RefSecond} style={ width >= 1024 && { bottom, opacity: opacityDelay }}>
        <ImageLeft fluid={data.imageLeft.childImageSharp.fluid} />
      </BoxHalfWidth>
      <BoxHalfWidth style={ width >= 1024 && { bottom, opacity: opacityDelay }}>
        <ImageRight fluid={data.imageRight.childImageSharp.fluid} />
      </BoxHalfWidth>
      <DetailsWrapper>
        <DetailsLarge />
      </DetailsWrapper>
    </Wrapper>
  );
};

export default AxellLogisticsGallery;
