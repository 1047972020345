import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary";
import DetailsLarge from "@components/svg/details-large";
import DetailsCases from "@components/svg/details-cases";

const Wrapper = tw.div`
  relative mx-20
  sm:flex sm:flex-wrap sm:mt-40 sm:mx-0
  lg:mt-140
`;

const Header = styled(motion.div)`
  ${tw`
    ml-20 mr-10 text-sm text-second leading-18 -tracking-021
    sm:self-center sm:w-1/2 sm:ml-40 sm:mr-0 sm:text-md sm:text-primary
    md:w-2/5
    lg:relative lg:w-540 lg:ml-0 lg:text-2xl lg:leading-52 lg:-tracking-024
    xl:left-110 xl:w-700
  `}
  
  br {
    ${tw`hidden xl:block`}
  }
`;

const Description = styled(motion.p)`
  ${tw`
    flex ml-20 mr-10 mb-40 text-sm text-second leading-20 -tracking-021
    sm:w-510 sm:mt-40 sm:ml-auto sm:mr-40 sm:mb-80
    md:w-500 md:mt-120
    lg:relative lg:w-640 lg:mt-190 lg:mb-145 lg:text-md lg:leading-30 lg:-tracking-027
    xl:ml-550 xl:mr-0 xl:whitespace-pre-line
  `}
  
  br {
    ${tw`hidden md:block`}
  }
`;

const CoverWrapper = tw(motion.div)`
  relative max-w-340 my-20 mx-auto
  sm:w-2/5 sm:max-w-none sm:ml-auto sm:mr-0
  xl:left-160 xl:w-545
`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden transform -rotate-90 -scale-y-100
    md:block md:absolute md:top-300 md:left-300 md:w-90
    lg:top-420 lg:left-400 lg:w-140
    xl:top-495 xl:left-580
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const DetailsWrapper = tw.div`
  hidden transform -scale-x-100
  md:block md:absolute md:top-335 md:w-160
  lg:top-530 lg:w-210
  xl:top-570 xl:w-360
`;

const DetailsCasesWrapper = tw.div`
  hidden
  md:block md:absolute md:-top-20 md:left-40 md:w-200
  lg:-top-120 lg:left-0 lg:w-340
  xl:-top-60 xl:w-525
`;

const AxellLogisticsHeader = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "cases/axell-cover.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 250, sectionStart + 600], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 250, sectionStart + 600], [-120, 0]);
  const xLeft = useTransform(scroll, [sectionStart + 250, sectionStart + 600], [120, 0]);

  const top = useTransform(scroll, [sectionStart + 850, sectionStart + 1200], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 850, sectionStart + 1200], [-9, 0]);
  const opacityDelay = useTransform(scroll, [sectionStart + 850, sectionStart + 1200], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Header style={ width >= 1024 && { opacity, x }}>
        {t('Cases:AxellLogistics:Heading1')}
        <br/>
        {t('Cases:AxellLogistics:Heading2')}
      </Header>
      <CoverWrapper style={ width >= 1024 && { opacity, x: xLeft }}>
        <Img fluid={data.cover.childImageSharp.fluid} />
      </CoverWrapper>
      <Description style={ width >= 1024 && { top, opacity: opacityDelay, rotate }}>
        {t('Cases:AxellLogistics:Description1')}
        <br/>
        {t('Cases:AxellLogistics:Description2')}
        <br/>
        {t('Cases:AxellLogistics:Description3')}
        <br/>
        {t('Cases:AxellLogistics:Description4')}
      </Description>
      <DetailsCasesWrapper>
        <DetailsCases />
      </DetailsCasesWrapper>
      <DetailsWrapper>
        <DetailsLarge />
      </DetailsWrapper>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default AxellLogisticsHeader;
