import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";

import LayoutPortfolio from "@layouts/portfolio";
import SEO from "@components/seo";
import AxellLogisticsHeader from "@components/cases/axell-logistics/header";
import AxellLogisticsGallery from "@components/cases/axell-logistics/gallery";

const AxellLogisticsPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  return (
    <LayoutPortfolio
      width={width}
      scroll={scrollY}
      name={'axell-logistics'}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Axell Logistics' })}
        description={intl.formatMessage({ id: 'SEO.Description.Axell Logistics' })}
      />
      <AxellLogisticsHeader width={width} scroll={scrollY} />
      <AxellLogisticsGallery width={width} scroll={scrollY} />
    </LayoutPortfolio>
  );
};

export default AxellLogisticsPage;
